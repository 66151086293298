
import { useI18n } from 'vue-i18n';
import { defineComponent, defineAsyncComponent, watch } from "vue";
import { useMeta } from "vue-meta";
import { usePiniaStore } from "@/store/indexPinia";
import { useBannerStore } from "@/store/modules/banner";
import { storeToRefs } from "pinia";
import Highlight from "@/components/Highlight.vue";
import Separator from "@/components/Separator.vue";
import HighlightStaticSkeleton from "@/components/skeleton/HighlightStaticSkeleton.vue";
import { useRoute } from 'vue-router';

const CarouselBrandsHome = defineAsyncComponent(() => import("@/components/CarouselBrandsHome.vue"))
const CarouselHomeHistory = defineAsyncComponent(() => import("@/components/CarouselHomeHistory.vue"))
const Newsletter = defineAsyncComponent(() => import("@/components/Newsletter.vue"))

export default defineComponent({
	components: { Highlight, Separator, CarouselHomeHistory, Newsletter, HighlightStaticSkeleton, CarouselBrandsHome },
	name: "Home",
	setup(){
		const { language, imagesBaseUrl } = storeToRefs(usePiniaStore());
		const bannerStore = useBannerStore();
		const { locale } = useI18n({ useScope: 'global' });
		const route = useRoute();

		useMeta({
			title: "Página inicial",
			link: [
				{ rel: 'canonical', href: "https://www.vigor.com.br" + route.fullPath },
			],
		});
		
		bannerStore.$reset();
		bannerStore.getBannerPage("?page=home", language.value);
		const { allBanners } = storeToRefs(useBannerStore());

		watch(locale, () =>{
			bannerStore.getBannerPage("?page=home", language.value);
		});

		return {
			imagesBaseUrl,
			allBanners,
		}
	},
});
